<template>
    <el-dialog
        :visible.sync="dialogVisible"
        :width="isChinese ? '600px' : '750px'"
        :title="$t('xin-xi-bian-ji')"
        center
        append-to-body
        :show-close="false"
    >
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            :label-width="isChinese ? '125px' : '200px'"
            label-position="right"
        >
            <el-form-item :label="$t('fen-lei-ming-cheng')" prop="chName" :rules="rulesRequire">
                <el-input
                    class="input2"
                    v-model="form.chName"
                    :placeholder="$t('qing-shu-ru-fen-lei-ming-cheng')"
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('fen-lei-ying-wen-ming')" prop="enName">
                <el-input
                    class="input2"
                    v-model="form.enName"
                    :placeholder="$t('qing-shu-ru-fen-lei-ying-wen-ming')"
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('guan-lian-chan-pin')" prop="ehName">
                <div class="input3">
                    <product-select v-model="form.productId" ref="productSelect"></product-select>
                </div>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="warning" size="small" @click="onSubmit" :loading="loading">{{ $t('ti-jiao') }}</el-button>
            <!-- <el-button type="danger" size="small" v-if="form.id" @click="onDelete" :loading="loading">删除</el-button> -->

            <el-button type="info" size="small" plain @click="dialogVisible = false">{{ $t('qu-xiao') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import formPage from '../../mixins/formPage';
import ProductSelect from '../../components/select/Product.vue';
export default {
    name: 'edit',
    mixins: [formPage],
    data() {
        return {
            dialogVisible: false,
            customCategoryId: 0,
            form: { chName: '', enName: '', productId: [] },
            rules: {}
        };
    },
    computed: {
        ...mapState(['buyersInfo'])
    },
    methods: {
        submit() {
            const form = { ...this.form };
            if (!this.form.id) {
                form.vendorInfoId = this.buyersInfo.vendorId;
            }
            this.$http
                .post('/customCategory/save', form, { body: 'json' })
                .then(res => {
                    this.$message.success(this.$t('ti-jiao-cheng-gong'));
                    this.dialogVisible = false;
                    this.$emit('getAll');
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        },
        show(data = {}) {
            this.form = { chName: '', enName: '', productId: [], ...data };
            this.dialogVisible = true;

            this.$nextTick(() => {
                this.$refs.productSelect.setValue();
            });
        },
        onDelete() {
            this.$alert(this.$t('shan-chu-jiang-wu-fa-hui-fu-que-ren-yao-shan-chu-mo'), this.$t('jing-gao'), {
                type: 'error'
            })
                .then(() => {
                    return this.$http.post(`/productParameter/del/${this.form.id}`);
                })
                .then(() => {
                    this.$message.success(this.$t('shan-chu-cheng-gong'));
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error(e.error);
                    }
                });
        }
    },
    components: {
        ProductSelect
    }
};
</script>

<style lang="scss" scoped>
.dialog-footer {
    .el-button {
        height: 36px;
        min-width: 120px;

        &.el-button--warning {
            min-width: 160px;
        }
    }
    .el-button + .el-button {
        margin-left: 20px;
    }
}
</style>
