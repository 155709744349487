<template>
    <div class="customCategory">
        <div class="userTitle">
            <span>{{ $t('bian-ji-fen-lei') }}</span>
            <p class="dividingLine"></p>
        </div>
        <!-- <empty-page v-if="menus.length === 0 && !loading">
            <span slot="text">暂无收藏{{ $t(typeName) }}</span>
        </empty-page> -->
        <div class="menu-tree">
            <el-tree
                :data="menus"
                :render-content="renderContent"
                :highlight-current="true"
                :expand-on-click-node="true"
                node-key="id"
                v-loading="loading"
                accordion
                @node-click="nodeClick"
                :default-expanded-keys="expandKeys"
                :default-checked-keys="expandKeys"
            >
            </el-tree>
            <el-button type="warning" size="small" @click="addRootMenu" style="margin-left: 24px;">{{$t('xin-zeng-fen-lei')}} </el-button>
        </div>

        <edit-form ref="edit" @getAll="getData" />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import editForm from './CustomCategoryEdit';
export default {
    name: 'customCategory',
    data() {
        return {
            dialogVisible: false,
            curr: null,
            loading: false,
            menus: [],
            menu: {
                name: '',
                path: '',
                icon: '',
                root: false,
                active: true
            },
            parent: null,
            currentRef: null,
            edit: false,
            icon: '',
            categories: [],
            category: null,
            expandKeys: [],
            authorities: []
        };
    },
    computed: {
        ...mapState(['userInfo', 'buyersInfo'])
    },
    mounted() {
        this.getData();
    },
    methods: {
        addRootMenu() {
            this.$refs.edit.show();
        },
        showAddDialog(node, data) {
            this.edit = false;
            this.parent = node.data;
            this.menu = {
                parent: node.data.id,
                name: '',
                path: '',
                active: true,
                root: false,
                icon: null,
                authorities: [{ name: 'ROLE_ADMIN' }]
            };
            this.icon = '';
            this.dialogVisible = true;
            setTimeout(() => {
                this.showIcon('');
            }, 100);
        },
        showEditDialog(node, data) {
            this.$refs.edit.show(data);
        },
        addMenu() {},
        remove(node, data) {
            console.log(node);
            this.$confirm(this.$t('que-ding-shan-chu-gai-fen-lei'), this.$t('ti-shi'), {
                confirmButtonText: this.$t('que-ding'),
                cancelButtonText: this.$t('qu-xiao'),
                type: 'warning',
                confirmButtonClass: 'el-button--warning'
            })
                .then(() => {
                    return this.$http.post(`/customCategory/del/${data.id}`);
                })
                .then(res => {
                    this.$message.success(this.$t('shan-chu-cheng-gong'));
                    this.getData();
                })
                .catch(e => {
                    this.loading = false;
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error(e.error);
                    }
                });
        },
        moveUp(node, data) {
            if (node.previousSibling) {
                this.loading = true;
                let sort0 = node.previousSibling.data.sort,
                    sort1 = node.data.sort;
                Promise.all([
                    this.$http.post(
                        '/customCategory/save',
                        {
                            ...node.data,
                            children: null,
                            sort: sort0
                        },
                        { body: 'json' }
                    ),
                    this.$http.post(
                        '/customCategory/save',
                        {
                            ...node.previousSibling.data,
                            children: null,
                            sort: sort1
                        },
                        { body: 'json' }
                    )
                ])
                    .then(_ => {
                        this.loading = false;
                        this.getData();
                    })
                    .catch(e => {
                        console.log(e);
                        this.loading = false;
                        this.$message.error(e.error);
                    });
            }
        },
        moveDown(node, data) {
            if (node.nextSibling) {
                this.loading = true;
                let sort0 = node.data.sort,
                    sort1 = node.nextSibling.data.sort;
                Promise.all([
                    this.$http.post(
                        '/customCategory/save',
                        {
                            ...node.data,
                            children: null,
                            sort: sort1
                        },
                        { body: 'json' }
                    ),
                    this.$http.post(
                        '/customCategory/save',
                        {
                            ...node.nextSibling.data,
                            children: null,
                            sort: sort0
                        },
                        { body: 'json' }
                    )
                ])
                    .then(_ => {
                        this.loading = false;
                        this.getData();
                    })
                    .catch(e => {
                        console.log(e);
                        this.loading = false;
                        this.$message.error(e.error);
                    });
            }
        },
        getData() {
            this.loading = true;
            this.$http
                .get('/customCategory/my', {
                    vendorId: this.buyersInfo.vendorId
                })
                .then(res => {
                    this.menus = res;
                    this.loading = false;
                });
        },
        renderContent(h, { node, data, store }) {
            return (
                <div
                    class={
                        this.menu.id == data.id || (this.menu.parent == data.id && !this.menu.id)
                            ? 'custom-tree-node selected'
                            : 'custom-tree-node'
                    }
                >
                    <span class="name">{this.getName(data)}</span>
                    <el-tag type="warning" size="small" effect="plain">
                        {this.$t('guan-lian-chan-pin')} {data.productId.length}
                    </el-tag>

                    <span class="flex1"></span>

                    <span class="opt">
                        <el-button
                            type="warning"
                            plain
                            on-click={e => {
                                this.moveUp(node, data), e.stopPropagation();
                            }}
                            class="up"
                            size="mini"
                            icon="el-icon-top"
                        >
                            {this.$t('shang-yi')}
                        </el-button>
                        <el-button
                            type="warning"
                            plain
                            size="mini"
                            on-click={e => {
                                this.moveDown(node, data), e.stopPropagation();
                            }}
                            icon="el-icon-bottom"
                        >
                            {this.$t('xia-yi')}
                        </el-button>
                        <el-button
                            type="warning"
                            plain
                            size="mini"
                            on-click={e => {
                                this.showEditDialog(node, data), e.stopPropagation();
                            }}
                            icon="el-icon-edit"
                        >
                            {this.$t('bian-ji')}
                        </el-button>
                        <el-button
                            type="warning"
                            plain
                            size="mini"
                            class="hide"
                            on-click={e => {
                                this.showAddDialog(node, data), e.stopPropagation();
                            }}
                            icon="el-icon-plus"
                        >
                            {this.$t('tian-jia')}
                        </el-button>
                        <el-button
                            type="warning"
                            plain
                            size="mini"
                            on-click={e => {
                                this.remove(node, data), e.stopPropagation();
                            }}
                            icon="el-icon-delete"
                        >
                            {this.$t('shan-chu')}
                        </el-button>
                    </span>
                </div>
            );
        },
        showIcon(val) {
            if (!this.$refs.iconContainer) return;
            if (FontAwesome.icon({ prefix: 'fas', iconName: val })) {
                this.$refs.iconContainer.innerHTML = '';
                let i = document.createElement('i');
                i.className = 'fas fa-' + val;
                this.$refs.iconContainer.append(i);
                FontAwesome.dom.i2svg();
                this.menu.icon = 'fas fa-' + val;
            } else if (FontAwesome.icon({ prefix: 'fab', iconName: val })) {
                this.$refs.iconContainer.innerHTML = '';
                let i = document.createElement('i');
                i.className = 'fab fa-' + val;
                this.$refs.iconContainer.append(i);
                FontAwesome.dom.i2svg();
                this.menu.icon = 'fab fa-' + val;
            } else {
                this.$refs.iconContainer.innerHTML = '';
                let i = document.createElement('i');
                i.className = 'fab fa-' + val;
                this.$refs.iconContainer.append(i);
                FontAwesome.dom.i2svg();
                this.menu.icon = '';
            }
        },
        nodeClick(data, node, el) {
            if (this.expandKeys[0] != data.id) {
                this.expandKeys = [data.id];
            }
        }
    },
    components: {
        editForm
    }
};
</script>
<style lang="scss" scoped>
.customCategory {
    position: relative;
}

.el-tree {
    margin: 40px;
}
.dividingLine {
    height: 1px;
    background-color: #dcdfe6;
}
.userTitle {
    border: none;
}
</style>

<style lang="scss">
.el-tree-node__content {
    height: 40px;
    display: flex;
    align-items: center;
    &:hover {
        background-color: #fff6e9;
    }
}

.el-tree {
    .is-checked {
        .el-button {
            display: inline-block;
            &.hide {
                display: none;
            }
        }
    }

    .el-tree-node {
        &:first-child {
        }
    }
}
.custom-tree-node {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 40px;
    padding-right: 40px;
    .name {
        font-size: 14px;
        color: #292c33;
        line-height: 20px;
        min-width: 2.6rem;
    }

    .el-tag {
        background-color: transparent;
    }

    .el-button {
        border-width: 0px;
        display: none;
    }

    &:hover {
        .el-button {
            display: inline-block;

            &.hide {
                display: none;
            }
        }
    }
}

.isEn{
    .custom-tree-node .name{
        min-width: 3.5rem;
        margin-right: 10px;
    }
}
</style>
